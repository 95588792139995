<template>
  <section id="gestione-agenzie">
    <!-- Table Container Card -->
    <b-row>
      <b-col cols="12">
        <b-card
          title="Agenzia"
        >
          <b-overlay
            :show="showOverlay"
            rounded="sm"
          >
            <validation-observer
              #default="{ handleSubmit }"
              ref="AgencyRules"
            >
              <b-form @submit.prevent="handleSubmit(saveAgency())">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label>Ragione Sociale</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="CardName"
                      >
                        <b-form-input
                          v-model="ObjectToEdit.company"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ragione Sociale"
                          :disabled="!$can('create', 'Dealer')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Partita Iva"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.piva"
                        placeholder="Partita IVA"
                        :disabled="!$can('create', 'Dealer')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      label="Indirizzo"
                      label-for="fh-desc"
                    >
                      <b-form-textarea
                        id="fh-desc"
                        v-model="ObjectToEdit.address"
                        placeholder="Descrizione"
                        rows="3"
                        max-rows="8"
                        :disabled="!$can('create', 'Dealer')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Stato"
                      label-for="country"
                    >
                      <v-select
                        v-model="ObjectToEdit.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :clearable="true"
                        :options="countries"
                        label="name"
                        index="code"
                        class="fixborder"
                        :reduce="val => val.code"
                        input-id="country"
                        :disabled="!$can('create', 'Dealer')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Provincia"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.provincia"
                        placeholder="Provincia"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Città"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.citta"
                        placeholder="Città"
                        :disabled="!$can('create', 'Dealer')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="CAP"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.cap"
                        placeholder="CAP"
                        :disabled="!$can('create', 'Dealer')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Telefono"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.telefono"
                        placeholder="Telefono"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Cellulare"
                    >
                      <b-form-input
                        v-model="ObjectToEdit.cellulare"
                        placeholder="Cellulare"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>E-Mail</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="email|required"
                        name="Mail"
                      >
                        <b-form-input
                          v-model="ObjectToEdit.companyEmail"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Mail"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>

                  <b-col md="4">
                    <b-form-group>
                      <label>Referente</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="name"
                      >
                        <b-form-input
                          v-model="ObjectToEdit.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Referente"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>E-Mail Referente</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="email|required"
                        name="Mail"
                      >
                        <b-form-input
                          v-model="ObjectToEdit.email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Mail"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Contatto Referente</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="contact"
                      >
                        <b-form-input
                          v-model="ObjectToEdit.contact"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Contatto"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col
                    v-if="$can('create', 'Dealer')"
                  >
                    <b-card title="Riferimenti Cliente">
                      <b-card-body>
                        <b-row>
                          <b-col md="3">
                            <b-form-group
                              label="Genertel"
                              label-for="codice-genertel"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeGenertel"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              />

                            </b-form-group>
                          </b-col><b-col md="3">
                            <b-form-group
                              label="Genertel Premium"
                              label-for="ref-genertel-premium"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeGenertelPremium"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col><b-col md="3">
                            <b-form-group
                              label="VIASAT"
                              label-for="codiceViasat"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeViasat"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="VIASAT Premium"
                              label-for="codiceViasat"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeViasatPremium"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="IMA"
                              label-for="codiceIMA"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeIma"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="IMA Premium"
                              label-for="codiceIMAPremium"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeImaPremium"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="AXA"
                              label-for="codiceAXA"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeAxa"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group
                              label="AXA Premium"
                              label-for="codiceAXAPremium"
                            >

                              <b-form-input
                                v-model="ObjectToEdit.codeAxaPremium"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                                :disabled="!$can('create','Dealer')"
                              />

                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card-body>

                    </b-card></b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="$can('write', 'Dealer')"
                    cols="4"
                  >

                    <b-card
                      title="Configurazioni"
                    >

                      <!-- body -->
                      <b-card-body>
                        <b-form-group
                          v-if="$can('create','Dealer')"
                          label="Provvigioni Personalizzate"
                          label-for="provvigioni-personalizzate"
                        >

                          <b-form-checkbox
                            v-model="ObjectToEdit.provvigioniPersonalizzate"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            switch
                            value="Y"
                            unchecked-value="N"
                            :disabled="!$can('create','Dealer')"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Mostra Provvigioni"
                          label-for="provvigioni"
                        >

                          <b-form-checkbox
                            v-model="ObjectToEdit.mostrareProvvigioni"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            switch
                            value="Y"
                            unchecked-value="N"
                            :disabled="!$can('write','Dealer')"
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col
                    v-if="$can('create', 'Dealer')"
                    cols="4"
                  >


                    <b-card

                      v-if="programs.length>0"
                      title="Programmi abilitati"
                    >

                      <!-- body -->
                      <b-card-body>
                        <div
                          v-for="(p,index) in programs"
                          :key="index"
                          class="d-flex justify-content-between align-items-center"
                        >
                          <b-media no-body>
                            <b-media-aside class="mr-75">
                              <b-form-group
                                label-for="is-enabled-product"
                              >
                                <b-form-checkbox
                                  v-model="ObjectToEdit.programs"
                                  input-id="is-enabled-program"
                                  switch
                                  :value="p"
                                >
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                              </b-form-group>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h6 class="mb-0">
                                {{ p.Name }}
                              </h6>
                              <!-- <small>{{ p.Name }}</small> -->
                            </b-media-body>
                          </b-media>
                        </div>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col cols="4">

                    <b-card
                      title="Utenti"
                    >
                      <b-card-body>
                        Totale utenti collegati: {{ listUsersTotal }}
                        <ul v-if="listUsers">
                          <li
                            v-for="user in listUsers"
                            :key="user.Id"
                          >
                            {{ user.fullName }} ({{ user.email }})
                          </li>

                        </ul>
                      </b-card-body>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- submit and reset -->
                  <!-- <b-col
                    v-if="ObjectToEdit.provvigioniPersonalizzate=='Y' && $can('create','Dealer') && ObjectToEdit.provvigioniConfigurate=='N' "
                    md="12"
                  >
                    <b-alert
                      variant="warning"
                      show
                    >
                      <div class="alert-body">
                        <span><feather-icon
                          icon="AlertTriangleIcon"
                          class="mr-50"
                        /><strong>Attenzione!</strong> Configura tutte le provvigioni</span>

                      </div>
                    </b-alert>
                  </b-col>-->
                  <b-col md="12">

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ (ObjectToEdit.Id)? "Salva" : "Aggiungi" }}
                    </b-button>
                    <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="reset"
                >
                  Reset
                </b-button> -->
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
        <b-card v-if="ObjectToEdit.provvigioniPersonalizzate=='Y'&& $can('create','Dealer')">
          <b-form>
            <validation-observer>
              <b-row v-if="ObjectToEdit.provvigioniConfigurate=='N' ">
                <b-col>
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      <span><feather-icon
                        icon="AlertTriangleIcon"
                        class="mr-50"
                      /><strong>Attenzione!</strong> Imposta le commissioni per tutti i pacchetti</span>

                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col><h3>Commissioni Personalizzate</h3></b-col>
              </b-row>
              <b-overlay
                :show="showOverlayCommissioni"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="Seleziona il Prgramma"
                      label-for="Prgramma"
                    >
                      <v-select
                        v-model="programSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ObjectToEdit.programs"
                        label="Name"
                        index="Id"
                        class="fixborder"
                        input-id="Id"
                        @input="caricaProdotti"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Seleziona il Prodotto"
                      label-for="country"
                    >
                      <v-select
                        v-model="productSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ListaProdotti"
                        label="Name"
                        class="fixborder"
                        input-id="Id"
                        @input="caricaPacchetti"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Seleziona il Pacchetto"
                      label-for="country"
                    >
                      <v-select
                        v-model="packageSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ListaPackages"
                        label="Name"
                        class="fixborder"
                        input-id="Id"
                        @input="caricaFattori"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row v-if="isReadyToEdit">

                  <b-col>
                    <coefficienti-dealer
                      :original-factor="OriginalFactor"
                      :factor-to-edit="FactorToEdit"
                      :name-pack="namePack"
                      :index-pack="indexPack"
                      :coefficienti-type="coefficientiType"
                      :commission-type="commissionType"
                      :agency-id="ObjectToEdit.Id"
                    />
                  </b-col>
                </b-row>


              </b-overlay></validation-observer>

          </b-form></b-card>
      </b-col>


    </b-row>
  </section>


</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, between, email,
} from '@validations'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BOverlay,
  BFormTextarea,
  BFormInput,
  BCardBody,
  BMedia,
  BFormCheckbox,
  BMediaAside,
  BMediaBody,
  BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ca } from 'date-fns/locale'
import agencyStoreModule from './agencyStoreModule'
import programStoreModule from '../Programs/programStoreModule'
import productStoreModule from '../Product/productStoreModule'
import CoefficientiDealer from "./CoefficientiDealer.vue"

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardBody,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    CoefficientiDealer,
    BAlert,
  },
  directives: {
    Ripple,
  },
  methods: {
    saveAgency() {
      this.$refs.AgencyRules.validate().then(success => {
        if (success) {
          store.dispatch('app-agency/AddUpdateAgency', { agency: this.ObjectToEdit, userData: this.userData })
            .then(response => {
              this.$swal({
                icon: "success",
                title: "Modificato!",
                text: "I dati sono stati aggiornati con successo!",

                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(resp => {
                this.$router.go({ name: 'edit-program', params: { id: this.indexPack } })
              })
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },


  },
  setup() {
    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    const PROGRAM_DEALER_STORE_MODULE_NAME = 'app-program'
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'
    const showOverlay = ref(true)
    const showOverlayCommissioni = ref(false)
    const countries = ref([])
    const programs = ref([])
    const userData = getUserData()
    const FactorToEdit = ref([])
    const OriginalFactor = ref([])
    const ListaPackages = ref([])
    const ListaProdotti = ref([])
    const programSelected = ref()
    const productSelected = ref()
    const packageSelected = ref()
    const program = ref()
    const namePack = ref()
    const indexPack = ref()
    const coefficientiType = ref()
    const commissionType = ref()
    const isReadyToEdit = ref(false)

    const emptyObject = {
      Id: null,
      address: "",
      company: "",
      companyEmail: "",
      contact: "",
      name: "",
      country: "IT",
      mostrareProvvigioni: 'Y',
      codeGenertel: "",
      codeGenertelPremium: "",
      codeViasat: "",
      codeViasatPremium: "",
      codeIma: "",
      codeImaPremium: "",
      codeAxa: "",
      codeAxaPremium: "",
      provvigioniPersonalizzate: "Y",
      provincia: "",
      citta: "",
      cap: "",
      telefono: "",
      cellulare: "",
      email: "",
      piva: "",

    }
    const ObjectToEdit = ref(emptyObject)
    const listUsers = ref([])
    const programsDateils = ref([])
    const listUsersTotal = ref(0)
    // Register module
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    if (!store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.registerModule(PROGRAM_DEALER_STORE_MODULE_NAME, programStoreModule)
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PROGRAM_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const fetchUsersByAgencies = agencyId => {
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchUsersByAgencies`, agencyId)
        .then(value => {
          listUsers.value = value.data.users
          listUsersTotal.value = value.data.total
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fecthDealerPackageFactor = (agencyId, packageId) => new Promise((resolve, reject) => {
      try {
        productStoreModule.actions.fecthDealerPackageFactor(null, { agencyId, packageId })
        //  store.dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fecthDealerPackageFactor`, { agencyId, packageId })
          .then(resp => {
            if (resp.data.ResultCode == 0) {
              /* Prove per risolvere il problema dell'aggiornamento
              FactorToEdit.value = resp.data.Data
              showOverlayCommissioni.value = false
              OriginalFactor.value = JSON.parse(JSON.stringify(resp.data.Data)) // resp.data.Data// JSON.parse(JSON.stringify(resp.data.Data))
*/
              resolve(resp.data.Data)
            } else {
              console.err("err", resp.data.ResultCode)
              reject(resp.data.ResultCode)
            }
          }).catch(err => {
            // showOverlayCommissioni.value = false
            console.err("err", err)
            reject(err)
          })
      } catch (err) {
        reject(err)
      }
    })

    const fetchAgency = Id => {
      if (Id == 'personal') {
        Id = userData.DealerID
      }
      fetchUsersByAgencies(Id)
      showOverlay.value = true
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchAgency`, {
        id: Id,
      })
        .then(response => {
          ObjectToEdit.value = response.data.agency
          for (let i = 0; i < ObjectToEdit.value.programs.length; i += 1) {
            delete ObjectToEdit.value.programs[i].products
          }
          store.dispatch(`${PROGRAM_DEALER_STORE_MODULE_NAME}/fetchPrograms`, {})
            .then(res => {
              programs.value = res.data.program.filter(x => x.IsEnabled == 'Y')
            })
            .catch(error => {
              if (error.response.status === 404) {
                programs.value = undefined
              }
            })
          showOverlay.value = false
        }).catch(error => {
          ObjectToEdit.value = null
          showOverlay.value = false
        })
    }
    watch(routeParams, () => {
      try {
        if (routeParams.value.id == 'new') {
          ObjectToEdit.value = emptyObject
        } else {
          fetchAgency(routeParams.value.id)
          fetchUsersByAgencies(routeParams.value.id)
        }
      } catch (error) {
        ObjectToEdit.value = null
      }
    })

    try {
      if (routeParams.value.id == 'new') {
        ObjectToEdit.value = emptyObject
      } else {
        fetchAgency(routeParams.value.id)
      }
    } catch (error) {
      ObjectToEdit.value = null
    }


    store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchCountries`, { })
      .then(res => {
        countries.value = res.data // .data.ohem.map(x => ({ label: `${x.lastName} ${x.firstName}`, value: x.empID }))
        // countries.value.push({ label: '', value: -1 })
      })
      .catch(error => {
        if (error.response.status === 404) {
          countries.value = undefined
        }
      })

    const caricaProdotti = value => {
      isReadyToEdit.value = false
      if (!value) {
        productSelected.value = null
        packageSelected.value = null
        ListaPackages.value = []
        ListaProdotti.value = []
        return
      }
      productSelected.value = null
      ListaProdotti.value = []
      packageSelected.value = null
      ListaPackages.value = []


      store.dispatch(`${PROGRAM_DEALER_STORE_MODULE_NAME}/fetchProgram`, { id: value.Id })
        .then(res => {
          console.log(res.data)
          ListaProdotti.value = res.data.program.products.map(x => x.product)
          //          program.value =
        })
        .catch(err => {
          console.log(err)
        })
    }

    const caricaPacchetti = value => {
      isReadyToEdit.value = false
      if (!value) {
        ListaPackages.value = []
        packageSelected.value = null
        return
      }
      ListaPackages.value = []
      packageSelected.value = null
      ListaPackages.value = value.packages
    }

    const caricaFattori = value => {
      if (!value) {
        isReadyToEdit.value = false
        return
      }
      showOverlayCommissioni.value = true

      fecthDealerPackageFactor(ObjectToEdit.value.Id, value.Id)
        .then(data => {
          /** **************** */
          FactorToEdit.value = data
          // showOverlayCommissioni.value = false
          OriginalFactor.value = data // JSON.parse(JSON.stringify(data)) // resp.data.Data// JSON.parse(JSON.stringify(resp.data.Data))
          /** **************** */
          namePack.value = value.Name
          indexPack.value = value.Id
          coefficientiType.value = value.PriceType
          commissionType.value = value.CommissionType
          isReadyToEdit.value = true
          showOverlayCommissioni.value = false
        }).catch(err => {
          console.log("err", err)
          isReadyToEdit.value = false
          showOverlayCommissioni.value = false
        })
    }


    return {
      ObjectToEdit,
      showOverlay,
      showOverlayCommissioni,
      fetchAgency,
      userData,
      countries,
      programs,
      listUsers,
      listUsersTotal,
      fecthDealerPackageFactor,
      OriginalFactor,
      FactorToEdit,
      ListaPackages,
      ListaProdotti,
      programSelected,
      packageSelected,
      caricaProdotti,
      caricaFattori,
      caricaPacchetti,
      program,
      namePack,
      indexPack,
      coefficientiType,
      commissionType,
      isReadyToEdit,
      productSelected,

    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .agency-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

